export const downloadDocumentNavigator = (
  data,
  fileName = "model-file",
  isBase64 = true,
  isCsv = true,
) => {
  console.log(data);
  if (data) {
    // Decodifica o Base64 para um array de bytes
    const byteCharacters = isBase64 ? atob(data) : data;
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const utf8Decoder = new TextDecoder("utf-8");
    const fileData = utf8Decoder.decode(byteArray);

    let blob;
    let fileExtension;

    if (isCsv) {
      blob = new Blob([fileData], {
        type: "text/csv;charset=utf-8;",
      });
      fileExtension = ".csv";
    } else {
      try {
        const jsonData = JSON.stringify(JSON.parse(fileData), null, 2);
        blob = new Blob([jsonData], {
          type: "application/json;charset=utf-8;",
        });
        fileExtension = ".json";
      } catch (error) {
        console.error("Erro ao processar JSON:", error);
        return;
      }
    }

    const file = new File([blob], fileName + fileExtension, {
      type: blob.type,
    });

    const url = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName + fileExtension;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
};
