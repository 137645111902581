/* eslint-disable @typescript-eslint/no-explicit-any */
export const downloadDocumentNavigator = (
  data,
  fileName = 'model-csv',
  isBase64 = true
) => {
  if (data) {
    // Decodifica o Base64 para um array de bytes
    const byteCharacters = isBase64 ? atob(data) : data;
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const utf8Decoder = new TextDecoder('utf-8');
    const csvData = utf8Decoder.decode(byteArray);

    const csvBlob = new Blob([csvData], {
      type: 'text/csv;charset=utf-8;'
    });

    const csvFile = new File([csvBlob], fileName, {
      type: 'text/csv;charset=utf-8;'
    });
    const url = URL.createObjectURL(csvFile);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName + '.csv';
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
};
