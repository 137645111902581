/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from 'configs';
import { endpointsUploader } from 'infra';

import {
  IPayloadLiveRenewal,
  IPayloadNewSubscription,
  IPayloadUnitaryRenewal,
  IResponseAction,
  IResponseDownload,
  IResponseModelCSV,
  IResponseUnitaryRenwal,
  IResponseDto
} from './actionTypes';

async function getRpaAction({
  page,
  offset,
  sort
}: {
  page: number;
  offset: number;
  sort?: string;
}): Promise<IResponseAction> {
  const response = await api.get(endpointsUploader.getRpaActionEndpoint, {
    params: {
      page,
      offset,
      sort
    }
  });
  return response.data;
}

async function getFindAllRpaAction({
  value = '',
  finalDate = '',
  initialDate = '',
  status = '',
  page = 0,
  offset = 10,
  sort = 'created_at'
}: {
  value?: string;
  finalDate?: string;
  initialDate?: string;
  status?: string;
  page?: number;
  offset?: number;
  sort?: string;
}): Promise<IResponseAction> {
  const response = await api.get(endpointsUploader.getRpaActionEndpoint, {
    params: {
      value,
      finalDate,
      initialDate,
      status,
      page,
      offset,
      sort
    }
  });
  return response.data;
}

async function postRpaActionUnitaryRenewal(
  payload: IPayloadUnitaryRenewal
): Promise<IResponseUnitaryRenwal[]> {
  const response = await api.post(
    endpointsUploader.getRpaActionEndpoint,
    payload
  );
  return response.data;
}

async function postRpaActionLive(
  payload: IPayloadLiveRenewal
): Promise<IResponseUnitaryRenwal[]> {
  const response = await api.post(
    endpointsUploader.getRpaActionEndpoint,
    payload
  );
  return response.data;
}

async function postRpaNewSubscription(
  payload: IPayloadNewSubscription
): Promise<IResponseDto> {
  const response = await api.post(
    endpointsUploader.getRpaActionNewSubscriptionEndpoint,
    payload
  );
  return response.data;
}

async function getModelCSV(): Promise<IResponseModelCSV> {
  const response = await api.get(endpointsUploader.getModelCSV);
  return response.data;
}

async function postUploadCSV(file: File): Promise<any> {
  const response = await api.post(endpointsUploader.postCSV, file);
  return response.data;
}

async function getDownloadActionAll(): Promise<IResponseDownload> {
  const response = await api.get(endpointsUploader.postDownloadActionAll);
  return response.data;
}

async function getDownloadActionByIds(
  actionsIds: string[]
): Promise<IResponseDownload> {
  const response = await api.post(
    endpointsUploader.postDownloadAction,
    actionsIds
  );
  return response.data;
}

async function getDownloadFlowAll(): Promise<IResponseDownload> {
  const response = await api.get(endpointsUploader.postDownloadFlowAll);
  return response.data;
}

async function getDownloadFlowByIds(
  actionsIds: string[]
): Promise<IResponseDownload> {
  console.log(actionsIds);
  const response = await api.post(
    endpointsUploader.postDownloadFlow,
    actionsIds
  );
  return response.data;
}

async function postCleanAll(): Promise<any> {
  const response = await api.post(endpointsUploader.postCleanAll);
  return response.data;
}

async function postCleanByAssinaturaId(assinaturas: string[]): Promise<any> {
  const response = await api.post(endpointsUploader.postCleanById, assinaturas);
  return response.data;
}

export const actionApi = {
  getRpaAction,
  getFindAllRpaAction,
  postRpaActionUnitaryRenewal,
  postRpaActionLive,
  getModelCSV,
  postUploadCSV,
  getDownloadActionAll,
  postCleanAll,
  postCleanByAssinaturaId,
  getDownloadActionByIds,
  getDownloadFlowAll,
  getDownloadFlowByIds,
  postRpaNewSubscription
};
