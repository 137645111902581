/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from '@intraversa-lab/styleguide';
import { MutationOptions } from 'infra';
import { useMutation } from 'react-query';

import { actionService } from '../actionService';

export function useGetDownloadFlow(options?: MutationOptions<any>) {
  console.log('bbbb');
  const mutation = useMutation<any, Error, any>({
    mutationFn: (flowsIds?: string[]) =>
      flowsIds?.length
        ? actionService.getDownloadRpaFlowByIds(flowsIds)
        : actionService.getDownloadRpaFlowAll(),
    retry: false,
    onError: error => {
      if (options?.onError) {
        options.onError(error.message);
      }
    },
    onSuccess: data => {
      toast({
        type: 'success',
        text: 'Sucesso',
        description: 'Download efetuado com sucesso.'
      });
      if (options?.onSuccess) {
        options.onSuccess(data);
      }
    }
  });

  return {
    isLoading: mutation.isLoading,
    onDownload: (flowsIds?: string[]) => mutation.mutate(flowsIds),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError
  };
}
