/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Modal, toast } from '@intraversa-lab/styleguide';
import { TextFieldRHF } from 'components/FormRHF/TextFieldRHF';
import { queryClient } from 'configs/queryClient';
import { QueryKeys } from 'infra';
import { unitarySchema } from './UnitarySchema';
import { usePostNewSubscription } from 'domains/ActionConfig/useCases/usePostNewSubscription';
import { IPayloadNewSubscription } from 'domains/ActionConfig/actionTypes';
import { InputSelectdRHF, TProps } from '../../components/FormRHF/InputSelectRHF';

interface IModalUnitaryRenawelProps {
  isOpen: boolean;
  onClose: () => void;
  type: string;
  title: string;
}

interface FormValues {
  paymentMethod: string;
  frequency?: string;
}


interface newSubscriptionPayload {
  planId: string;
  paymentMethod: string;
  customerName: string;
  customerEmail: string;
  customerDoc: string;
  customerPhone: string;
}

const optionsPaymentMethod: TProps[] = [
  { label: 'Boleto', value: 'BOLETO' },
  { label: 'Pix', value: 'PIX' },
  { label: 'BolePix', value: 'BOLE_PIX' },
  { label: 'Dinheiro', value: 'DINHEIRO' },
];

export const ModalNewSubscription: React.FC<IModalUnitaryRenawelProps> = ({
  isOpen,
  onClose,
  type,
}) => {

  const { control, watch } = useForm<FormValues>();

  const selectedValue = watch('paymentMethod', '');

  const methods = useForm<newSubscriptionPayload>({
    resolver: zodResolver(unitarySchema),
    defaultValues: {
      planId: '',
      paymentMethod: '',
      customerName: '',
      customerEmail: '',
      customerDoc: '',
      customerPhone: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods



  const { postNewSubscription } = usePostNewSubscription({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GetFindAllActionRpa);
      onClose();
    }
  });



  function onSubmit(data: newSubscriptionPayload) {
    console.log(data);
    const result: IPayloadNewSubscription = {
      planId: data.planId,
      paymentMethod: selectedValue,
      customerName: data.customerName,
      customerEmail: data.customerEmail,
      customerDoc: data.customerDoc,
      customerPhone: data.customerPhone,
    };
    console.log(result);
    postNewSubscription(result);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        maxWidth={745}
        showCloseButton={false}
        defaultPaddingContent={'64px 40px'}
      >
        <div className="z-10">
          <div className="space-y-4 mb-8">
            <h1 className="text-[#161F28] text-4xl font-normal font-merriweather">
              Adicionar Nova Assinatura
            </h1>
            <h3 className="text-[#75808A] text-base">
              Preencha os campos abaixo para adicionar uma nova assinatura.
            </h3>
          </div>

          <FormProvider {...methods}>
            <div className="space-y-4 mb-8">
             
            <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">ID Plano</label>
                <div className="flex-1">
                  <TextFieldRHF name="planId" placeholder="ID do Plano" />
                </div>
              </div>



              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">Nome</label>
                <div className="flex-1">
                  <TextFieldRHF name="customerName" placeholder="Nome do Cliente" />
                </div>
              </div>

              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">Email</label>
                <div className="flex-1">
                  <TextFieldRHF name="customerEmail" placeholder="Email do Cliente" />
                </div>
              </div>

              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">Documento</label>
                <div className="flex-1">
                  <TextFieldRHF name="customerDoc" placeholder="Documento do Cliente" />
                </div>
              </div>

              <div className="flex items-center">
                <label className="w-[120px] mr-6 font-medium">Telefone</label>
                <div className="flex-1">
                  <TextFieldRHF name="customerPhone" placeholder="Telefone do Cliente" />
                </div>
              </div>


              <div className="flex items-center" style={{ gap: '64px' }}>
                <p
                  className="font-medium"
                  style={{ fontFamily: 'Roboto', textWrap: 'nowrap' }}
                >
                  Pagamento
                </p>

                <div className="w-full">
                  <InputSelectdRHF
                    placeholder={'Selecione o Metódo de Pagamento'}
                    options={optionsPaymentMethod}
                    name="paymentMethod"
                    control={control}
                    isError={false}
                  />
                </div>
              </div>

            <div className="flex items-center space-x-4">
                <Button
                  variant="outlined"
                  className="flex-1"
                  onClick={onClose}
                  style={{ textTransform: 'none' }}
                >
                  Cancelar
                </Button>
                <Button
                  className="flex-1"
                  type="submit"
                  onClick={() => handleSubmit(onSubmit)()}
                  style={{ textTransform: 'none' }}
                >
                  Adicionar
                </Button>
              </div>
            </div>
          </FormProvider>
        </div>
      </Modal>
    </>
  );
};
