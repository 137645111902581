import { z } from 'zod';

export const unitarySchema = z.object({
  planId: z.string().min(1, 'Campo Obrigatório'),
  customerName: z.string().min(1, 'Campo Obrigatório'),
  customerEmail: z.string().min(1, 'Campo Obrigatório'),
  customerPhone: z.string().min(1, 'Campo Obrigatório'),
  customerDoc: z.string().min(1, 'Campo Obrigatório')
});

export type unitarySchema = z.infer<typeof unitarySchema>;
